import { Component, Input, OnInit, NgModule } from '@angular/core';
import { MemberModelKomship } from '../../../../models/member.model';
import { MemberModelBoostr } from '../../../../models/member-boostr.model';
import { DataMemberComponent } from 'src/app/modules/dashboard/components/data-member/data-member.component';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})

export class TableComponent implements OnInit {
  @Input() dataKomship: MemberModelKomship[] = []
  @Input() dataBoostr: MemberModelBoostr[] = []
  @Input() header!: string[]
  @Input() headers!: string[]
  constructor() { }

  ngOnInit() {
  }

}
