import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SharedService {
  nominalSource = new BehaviorSubject<any>(null)
  bankAccountId = new BehaviorSubject<any>(null)

  constructor() { }

  changeNominal(nominal: any) {
    this.nominalSource.next(nominal)
  }

  changeBank(bank: any) {
    this.bankAccountId.next(bank)
  }
}
