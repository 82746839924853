import { TokenStorageService } from 'src/app/services/token-storage.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseService } from 'src/app/services/base.service';
import { Subject, takeUntil } from 'rxjs';
import { Profile } from 'src/app/models/profile.model';
import { DELETE_ALL_NOTIF, DELETE_NOTIF, GET_ALL_NOTIF, GET_PROFILE, READ_ALL, READ_BY_ID } from 'src/app/constans/endpoint.const';
import { NotificationModel } from '../../../../../models/notification.model';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Input() active: boolean = true;
  @Output() collapseToogle = new EventEmitter();
  getNotesNotify: string = '';
  // username: string = '';
  destroy$ = new Subject();
  detailProfile!: Profile;
  getNotif: NotificationModel = {
    count_notify: 0,
    user_id: 0,
    detail_notify: [],
  };
  isReadNotif: number = 0;
  isClear: boolean = false;

  constructor(
    private storageService: TokenStorageService,
    private baseService: BaseService,
    private modalService: NgbModal,
    config: NgbModalConfig
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }
  ngOnInit(): void {
    this.getProfile();
    this.getNotification();
  }
  getProfile(): void {
    this.baseService
      .getDataWithToken(GET_PROFILE)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.detailProfile = res.data;
      });
  }

  collapseClick(): void {
    this.active = !this.active;
    this.collapseToogle.emit(this.active);
  }

  logout(): void {
    this.storageService.signOut();
    window.location.reload();
  }
  getNotification(): void {
    this.baseService
      .getDataWithToken(GET_ALL_NOTIF)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.getNotif = res.data;
        if (
          this.getNotif.detail_notify.length === 0 ||
          this.getNotif.detail_notify === null
        ) {
          this.isClear = true;
        }
      });
  }
  clearAllNotif() {
    this.baseService
      .deleteDataWithToken(DELETE_ALL_NOTIF)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.status === 'success') {
          this.getNotification();
        }
      });
  }
  clearNotif(id: number) {
    const params = DELETE_NOTIF + id;
    this.baseService
      .deleteDataWithToken(params)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.status === 'success') {
          this.getNotification();
        }
      });
  }
  readNotif(id: number) {
    const endpoint = READ_BY_ID + id;
    this.baseService
      .putDataWithToken(endpoint)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.status === 'success') {
          this.getNotification();
        }
      });
  }
  readAllNotif() {
    this.baseService
      .putDataWithToken(READ_ALL)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.status === 'success') {
          this.getNotification();
        }
      });
  }
  open(content: any, notes: any) {
    this.getNotesNotify = notes;
    this.modalService.open(content);
  }
}
