import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './modules/auth/auth.component';
import { MainLayoutComponent } from './modules/layout/main-layout/main-layout.component';
import { AuthGuard } from './guards/auth.guard';
import { AuthGuardLogged } from './guards/authLogged.guard';
import { TermAndConditionComponent } from './modules/term-and-condition/term-and-condition.component';
import { PrivacyPolicyComponent } from './modules/privacy-policy/privacy-policy.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    component: AuthComponent,
    canActivate: [AuthGuardLogged],
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/layout/main-layout/main-layout.module').then(
        (m) => m.MainLayoutModule
      ),
  },
  {
    path: 'term-condition',
    component: TermAndConditionComponent,
  },
  {
    path: 'privacy',
    component: PrivacyPolicyComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [AuthGuard, AuthGuardLogged],
  exports: [RouterModule]
})
export class AppRoutingModule { }
