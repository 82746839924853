import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { OTPModel } from '../../../../models/otp-input.model';
import { CounterDirective } from '../../../../directives/timer.directive';
import { OtpPipe } from '../../../../pipes/otp.pipe';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConst } from 'src/app/constans/modal.const';
import {
  CHECK_WA,
  CREATE_REKENING,
  GET_PROFILE,
  SEND_OTP_SMS,
  SEND_OTP_SMS_CHECK,
  SEND_OTP_WA,
  SEND_OTP_WA_CHECK,
} from 'src/app/constans/endpoint.const';
import { BaseService } from 'src/app/services/base.service';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { Profile } from 'src/app/models/profile.model';

@Component({
  selector: 'otp-add-rekening',
  templateUrl: './otp-add-rekening.component.html',
  styleUrls: ['./otp-add-rekening.component.css'],
})
export class OtpAddRekeningComponent implements OnInit {
  modal = ModalConst;
  @Input() setting: OTPModel = {
    numbersOnly: true,
    length: 6,
    timer: 0,
    timerType: 0,
  };
  @Input() account_name: string = '';
  @Input() accountNumber!: number;
  @Input() bank!: any;
  @Output() onValueChange = new EventEmitter<any>();
  @ViewChildren(CounterDirective) CounterDirective: any;
  otpForm!: FormGroup;
  inputControls: FormControl[] = new Array(this.setting.length);
  componentKey =
    Math.random().toString(36).substring(2) + new Date().getTime().toString(36);
  public counter!: any;
  destroy$ = new Subject();
  detailProfile!: Profile;
  isSms: boolean = false;
  isWhatsapp: boolean = false;
  isCheckWhatsapp: boolean = false;
  timeDown: boolean = false;
  resendTryOtp: boolean = false;
  isError: boolean = false;
  isErrorMessage: boolean = false;
  isSendOtp: boolean = false;
  otpWhatsapp: string[] = [];
  otpSms: string[] = [];
  count!: number;

  constructor(
    private keysPipe: OtpPipe,
    private modalService: NgbModal,
    private baseService: BaseService,
    private activeModal: NgbActiveModal
  ) {
    this.countdownTimer();
  }

  public ngOnInit(): void {
    this.otpForm = new FormGroup({});
    this.getProfile();
    for (let index = 0; index < this.setting.length; index++) {
      this.otpForm.addControl(this.getControlName(index), new FormControl());
    }
  }
  handleClose(e: any): void {
    this.activeModal.dismiss();
  }
  getProfile(): void {
    this.baseService
      .getDataWithToken(GET_PROFILE)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.detailProfile = res.data;
        const phone = res.data.no_hp;
        this.detailProfile.secret_no =
          Array(phone.length - 3).join('*') + phone.slice(-3);
        this.checkWhatsapp();
      });
  }

  public ngAfterViewInit(): void {
    let containerItem = document.getElementById(`c_${this.componentKey}`);
    if (containerItem) {
      let ele: any = containerItem.getElementsByClassName('.otp-input')[0];
      if (ele && ele.focus) {
        ele.focus();
      }
    }
  }

  private getControlName(idx: any) {
    return `ctrl_${idx}`;
  }

  isLeftArrow(e: any) {
    return this.isKeyCode(e, 37);
  }

  isRightArrow(e: any) {
    return this.isKeyCode(e, 39);
  }

  isBackspaceOrDelete(e: any) {
    return (
      e.key === 'Backspace' ||
      e.key === 'Delete' ||
      this.isKeyCode(e, 8) ||
      this.isKeyCode(e, 46)
    );
  }

  isKeyCode(e: any, targetCode: any) {
    var key = e.keyCode || e.charCode;
    if (key == targetCode) {
      return true;
    }
    return false;
  }

  keyUp(e: any, inputIdx: number) {
    this.otpSms[e.target.value];
    this.otpWhatsapp[e.target.value];
    let nextInputId = this.appendKey(`otp_${inputIdx + 1}`);
    let prevInputId = this.appendKey(`otp_${inputIdx - 1}`);
    if (this.isRightArrow(e)) {
      this.setSelected(nextInputId);
      return;
    }
    if (this.isLeftArrow(e)) {
      this.setSelected(prevInputId);
      return;
    }
    let isBackspace = this.isBackspaceOrDelete(e);
    if (isBackspace && !e.target.value) {
      this.setSelected(prevInputId);
      this.rebuildValue();
      return;
    }
    if (!e.target.value) {
      return;
    }
    if (this.isValidEntry(e)) {
      this.focusTo(nextInputId);
    }
    this.rebuildValue();
  }

  appendKey(id: any) {
    return `${id}_${this.componentKey}`;
  }

  setSelected(eleId: any) {
    this.focusTo(eleId);
    let ele: any = document.getElementById(eleId);
    if (ele && ele.setSelectionRange) {
      setTimeout(() => {
        ele.setSelectionRange(0, 1);
      }, 0);
    }
  }

  isValidEntry(e: any) {
    var inp = String.fromCharCode(e.keyCode);
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    return (
      isMobile ||
      /[a-zA-Z0-9-_]/.test(inp) ||
      (this.setting.allowKeyCodes &&
        this.setting.allowKeyCodes.includes(e.keyCode)) ||
      (e.keyCode >= 96 && e.keyCode <= 105)
    );
  }

  focusTo(eleId: any) {
    let ele: any = document.getElementById(eleId);
    if (ele) {
      ele.focus();
      ele.selectionStart = ele.selectionEnd = 100;
    }
  }

  rebuildValue() {
    let val = '';
    this.keysPipe.transform(this.otpForm.controls).forEach((k) => {
      if (this.otpForm.controls[k].value) {
        val += this.otpForm.controls[k].value;
      }
    });
    this.onValueChange.emit(val);
  }

  public onCounterChange(e: any): void {
    this.counter = e;
    if (this.counter == 0) {
      this.onValueChange.emit(-1);
    }
  }

  ressendOtp(): void {
    this.CounterDirective.first.startTimer();
    this.onValueChange.emit(-2);
  }

  public formatSecsToMins(time: any) {
    // Hours, minutes and seconds
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = '';
    if (hrs > 0) {
      ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
    }
    ret += '' + mins + ':' + (secs < 10 ? '0' : '');
    ret += '' + secs;
    return ret;
  }
  sendSms(e: any) {
    this.isSendOtp = false;
    const formdata = {
      phone_number: this.detailProfile.no_hp,
      session: 'rekening',
      activity: 'BANK ACCOUNT',
    };
    this.baseService
      .postDataWithAuth(SEND_OTP_SMS, formdata)
      .pipe(debounceTime(500))
      .subscribe(
        (resp: any) => {
          this.isSms = true;
          this.timeDown = true;
          this.countdownTimer();
          this.restartCountdown();
          this.count = resp.data?.expired_at;
          this.isSendOtp = false;
        },
        (err) => {
          this.isSendOtp = true;
        }
      );
  }
  checkOtpSms(e: any) {
    if(this.otpSms.length === 6) {
      const formdata = {
        otp: this.otpSms.join(''),
        session: 'rekening',
      };
      const validation = this.otpSms.every((item) => {
        return item !== undefined && item !== '';
      });
  
      if (validation === true) {
        this.baseService
          .postDataWithAuth(SEND_OTP_SMS_CHECK, formdata)
          .pipe(debounceTime(500))
          .subscribe(
            (resp: any) => {
              if (resp.status === 'success') {
                const params: object = {
                  account_no: this.accountNumber,
                  bank_name: this.bank,
                  account_name: this.account_name,
                };
                this.baseService
                  .postDataWithToken(CREATE_REKENING, params)
                  .pipe(takeUntil(this.destroy$))
                  .subscribe(
                    (response) => {
                      if (response.status === 'failed') {
                        this.isError = true;
                      }
                      if (response.status === 'success') {
                        this.activeModal.dismiss();
                        this.getProfile();
                      }
                    },
                    (err) => {}
                  );
              }
              if (resp.status === 'failed') {
                this.isErrorMessage = true;
              }
            },
            (err) => {
              if (err.error.status === 'failed') {
                this.isErrorMessage = true;
              }
            }
          );
      }
    }
  }
  sendWhatsapp(e: any) {
    this.isSendOtp = false;
    const formdata = {
      no_hp: this.detailProfile.no_hp,
      session: 'rekening',
      activity: 'Bank ACCOUNT',
    };
    this.baseService
      .postDataWithAuth(SEND_OTP_WA, formdata)
      .pipe(debounceTime(500))
      .subscribe(
        (resp: any) => {
          this.isSendOtp = false;
          this.count = resp.data?.expired_at;
          this.isWhatsapp = true;
          this.timeDown = true;
          this.countdownTimer();
          this.restartCountdown();
          
        },
        (err) => {
          this.isSendOtp = true;
        }
      );
  }
  checkOtpWhatsapp(e: any) {
    if(this.otpWhatsapp.length === 6){
      const formdata = {
        otp: this.otpWhatsapp.join(''),
        session: 'rekening',
      };
      const validation = this.otpWhatsapp.every((item) => {
        return item !== undefined && item !== '';
      });
  
      if (validation === true) {
        this.baseService
          .postDataWithAuth(SEND_OTP_WA_CHECK, formdata)
          .pipe(debounceTime(500))
          .subscribe(
            (resp: any) => {
              if (resp.status === 'success') {
                const params: object = {
                  account_no: this.accountNumber,
                  bank_name: this.bank,
                  account_name: this.account_name,
                };
                this.baseService
                  .postDataWithToken(CREATE_REKENING, params)
                  .pipe(takeUntil(this.destroy$))
                  .subscribe(
                    (response) => {
                      if (response.status === 'failed') {
                        this.isError = true;
                      }
                      if (response.status === 'success') {
                        this.activeModal.dismiss();
                        this.getProfile();
                        location.reload();
                      }
                    },
                    (err) => {}
                  );
              }
              if (resp.status === 'failed') {
                this.isErrorMessage = true;
                this.onKeyDown(e);
              }
            },
            (err) => {
              if (err.error.status === 'failed') {
                this.isErrorMessage = true;
                this.onKeyDown(e);
              }
            }
          );
      }
    }
  }
  resendOtpWhatsapp(e: any) {
    this.resendTryOtp = false;
    this.timeDown = true;
    this.countdownTimer();
    this.restartCountdown();
    this.sendWhatsapp(e);
  }
  resendOtpSms(e: any) {
    this.resendTryOtp = false;
    this.timeDown = true;
    this.countdownTimer();
    this.restartCountdown();
    this.sendSms(e);
  }
  countdownTimer(): any {
    if (this.timeDown) {
      setTimeout(() => {
        this.count -= 1;
        if (this.count > 0) {
          this.countdownTimer();
        }
        if (this.count === 0) {
          this.timeDown = false;
          this.resendTryOtp = true;
        }
      }, 1000);
    }
  }
  restartCountdown() {
    this.count;
  }
  checkWhatsapp(): void {
    this.baseService
      .postDataWithAuth(CHECK_WA, {
        phone_no: this.detailProfile.no_hp,
      })
      .subscribe(
        (resp: any) => {
          if (resp.data === 'valid') {
            this.isCheckWhatsapp = true;
          } else {
            this.isCheckWhatsapp = false;
          }
        },
        (err) => {
          if (err.error.status === 'failed') {
            this.isCheckWhatsapp = false;
          }
        }
      );
  }
  onKeyDown(event: KeyboardEvent) {
    if (event.code === 'Backspace') {
      this.isErrorMessage = false;
      this.otpWhatsapp = this.otpWhatsapp.slice(0, this.otpWhatsapp.length - 1);
      this.otpSms = this.otpSms.slice(0, this.otpSms.length - 1);
    }
  }
}
