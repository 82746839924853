import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConst } from '../../../../constans/modal.const';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  @Input() tittle: string = "Title In Here"
  @Input() description: string = "Description In Here"
  @Input() closeText: string = "Close Text"
  @Input() confirmText: string = "Confirm Text"

  modal= ModalConst
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  handleClose(e: any): void{
    this.activeModal.dismiss(this.modal.CLOSE)
  }

  confirmClose(e: any): void{
    this.activeModal.dismiss(this.modal.CONFIRM)
  }
}
