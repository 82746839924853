<div
  class="d-flex flex-column flex-md-row align-items-center justify-content-between"
>
  <div class="flex-row d-flex align-items-center">
    <div>List per halaman:</div>
    <div
      class="btn-group ms-2"
      (change)="onChangePerpage($event)"
      role="group"
      aria-label="First group"
    >
      <div *ngFor="let item of showPerpage; let i = index">
        <div
          [ngClass]="{ 'btn-primary': perPage === +item }"
          class="d-flex flex-row gap-3 btn ms-1 radio-container"
        >
          <input
            type="radio"
            [name]="'radio'"
            [id]="'radio'"
            [value]="item"
            class="btn"
          />
          <label for="">{{ item }}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="btn-group mt-3 mt-md-0" role="group" aria-label="First group">
    <button
      [disabled]="pagination.PAGE === 1"
      (click)="
        pagination.PAGE === 1 ? false : onHandlePage(pagination.PAGE - 1)
      "
      type="button"
      class="btn"
    >
      <i-feather name="chevron-left"></i-feather>
    </button>
    <div *ngIf="pagination.PAGE_COUNT > 1">
      <ng-container *ngIf="pagination.PAGE > 3">
        <button
          type="button"
          class="btn"
          [ngClass]="{ 'btn-primary': pagination.PAGE === 1 }"
          (click)="onHandlePage(1)"
        >
          1
        </button>
        <span> ... </span>
      </ng-container>
      <button
        type="button"
        class="btn"
        *ngFor="let item of getPaginationRange()"
        [ngClass]="{ 'btn-primary': item === pagination.PAGE }"
        (click)="onHandlePage(item)"
      >
        {{ item }}
      </button>
      <ng-container *ngIf="pagination.PAGE_COUNT - pagination.PAGE > 2">
        <span> ... </span>
        <button
          type="button"
          class="btn"
          [ngClass]="{
            'btn-primary': pagination.PAGE === pagination.PAGE_COUNT
          }"
          (click)="onHandlePage(pagination.PAGE_COUNT)"
        >
          {{ pagination.PAGE_COUNT }}
        </button>
      </ng-container>
    </div>
    <div *ngIf="pagination.PAGE_COUNT === 1">
      <button
        type="button"
        class="btn"
        *ngFor="let item of getPaginationRange()"
        [ngClass]="{ 'btn-primary': item === pagination.PAGE }"
        (click)="onHandlePage(item)"
      >
        {{ item }}
      </button>
    </div>
    <button
      type="button"
      [disabled]="pagination.PAGE_COUNT === pagination.PAGE"
      (click)="
        pagination.PAGE_COUNT === pagination.PAGE
          ? false
          : onHandlePage(pagination.PAGE + 1)
      "
      class="btn"
    >
      <i-feather name="chevron-right"></i-feather>
    </button>
  </div>
</div>
