const ENDPOINT = `${environment.base_url}/api/v1`;
const ENDPOINT_NEW = `${environment.base_url}/api/v2`;
const ENDPOINTKOMSHIP = `${environment.base_url_komship}/api`;

const V1 = `${ENDPOINTKOMSHIP}/v1`;
import { environment, environment_auth } from '../../environments/environment';
import { environment_komship } from '../../environments/environment';
import { environment_komship_bank } from '../../environments/environment';
import { environment_komship_otp } from '../../environments/environment';
import { environment_komtim } from '../../environments/environment';
import { environment_komtim_check_bank } from '../../environments/environment';

// const ENDPOINT = `${environment.base_url}/api/v1`;
const ENDPOINT_KOMSHIP = `${environment_komship.base_url}/api/v1`;
const ENDPOINT_KOMSHIP_BANK = `${environment_komship_bank.base_url}/api/v1`;
const ENDPOINT_KOMSHIP_OTP = `${environment_komship_otp.base_url}/api/v2`;
const ENDPOINT_KOMTIM = `${environment_komtim.base_url}/api`;
const ENDPOINT_KOMTIM_CHECK_BANK = `${environment_komtim_check_bank.base_url}/api/v1`;

export const CHECK_EMAIL = `${ENDPOINT}/register/check-email`;
export const REGISTER = `${ENDPOINT}/register`;
export const REGISTER_EXISTING = `${ENDPOINT}/register/register-existing`;
export const RESEND_VERIFICATION = `${ENDPOINT}/register/resend-verification`;
export const LOGIN = `${ENDPOINT}/auth/login`;
export const FORGOT_PASSWORD = `${ENDPOINT}/auth/forgot-password`;
export const GET_PROFILE = `${ENDPOINT}/auth/get-profile`;
export const RESET_PASSWORD = `${ENDPOINT}/auth/reset-password`;
export const CREATE_PIN = `${ENDPOINT_KOMSHIP}/pin/store`;
export const UPDATE_PIN = `${ENDPOINT_KOMSHIP}/pin/update`;
export const FORGET_PIN = `${ENDPOINT_KOMSHIP}/send-otp`;
export const CHECK_PIN = `${ENDPOINT_KOMSHIP}/pin/check`;
export const BANK_ACCOUNT = `${ENDPOINT_KOMSHIP}/bank-account`;
export const BAR_NOTIF = `${ENDPOINT_KOMSHIP}/partner/notification-bar`;
export const CREATE_REKENING = `${ENDPOINT_KOMSHIP_BANK}/bank-account/store`;
export const CHECK_WA = `${ENDPOINT_KOMSHIP_BANK}/check-wa`;
export const SEND_OTP_WA = `${ENDPOINT_KOMSHIP_BANK}/user/send/otp/wa`;
export const SEND_OTP_WA_CHECK = `${ENDPOINT_KOMSHIP_BANK}/user/send/otp/wa/check`;
export const SEND_OTP_SMS = `${ENDPOINT_KOMSHIP_OTP}/partner/sms/otp`;
export const SEND_OTP_SMS_CHECK = `${ENDPOINT_KOMSHIP_OTP}/partner/sms/otp/verification`;
export const CHECK_OWNER = `${ENDPOINT_KOMTIM_CHECK_BANK}/bank/check-bank-owner`;
export const BANK_CHECK = `${ENDPOINT_KOMTIM_CHECK_BANK}/bank/check`;
export const UPDATE_EMAIL = `${ENDPOINT_KOMTIM}/user/partner/update-profile/email`;
export const AUTH_EMAIL = `${ENDPOINT_KOMSHIP}/user/send/otp/email`;
export const AUTH_CHECK_EMAIL = `${ENDPOINT_KOMSHIP}/user/send/otp/email/check`;
export const CHECK_OTP_EMAIL = `${ENDPOINT_KOMSHIP}/auth-otp`;

//DASHBOARD
export const COUPON_CREATE = `${ENDPOINT}/coupon/create`;
export const MEMBER_AFFILIATOR_COUNT = `${ENDPOINT}/member/affiliator/count`;
export const MEMBER_AFFILIATOR_COUNT_MOUNTH = `${ENDPOINT}/member/affiliator/count-specific-month`;
export const AFFILIATOR_GET_MEMBERS = `${ENDPOINT}/member/affiliator/get-members`;
export const COUPON = `${ENDPOINT}/coupon/fetch`;
export const AFFILIATE = `${ENDPOINT}/affiliate/fetch`;
export const AFFILIATE_COUNT = `${ENDPOINT}/member/affiliator/count`;
export const AFFILIATE_BALANCE = `${ENDPOINT}/affiliator/balance`;
export const LEADERBOARD_DASHBOARD = `${ENDPOINT}/affiliator/leaderboard`;
export const LEADERBOARD_DASHBOARD_NEW = `${ENDPOINT_NEW}/affiliator/leaderboard`;

//DETAIL MEMBER
export const AFFILIATOR_MEMBERS_DETAIL = `${ENDPOINT}/member/affiliator/details`;

// DURIAN PAY
// export const FETCH_BANK_LIST = environment.durian_pay.base_url + '/disbursements/banks'
export const VALIDATE_BANK_ACCOUNT =
  environment.durian_pay.base_url + '/disbursements/validate';
export const GET_BANK = `${ENDPOINT_KOMTIM}/xendit/disbursementbankAvailable`;

// RIWAYAT KOMISI
export const WITHDRAWL_KOMISI = `${ENDPOINT}/withdrawal/affiliator/withdraw`;
export const WITHDRAWL_KOMISI_HISTORY = `${ENDPOINT}/withdrawal/affiliator/history`;
export const MUTATION_KOMISI = `${ENDPOINT}/transaction/affiliator/history`;
export const BANK_USER_LIST = `${ENDPOINT}/withdrawal/affiliator/bank`;
export const VERIF_PIN = `${ENDPOINT}/profile/pin/verify`;

// Komship

export const AUTHPIN = V1 + '/pin/auth';

//COUPON MANAGE
export const CREATE_COUPON = `${ENDPOINT}/coupon/create`;
export const UPDATE_COUPON = `${ENDPOINT}/coupon/update`;

// NOTIFICATION NAVBAR
export const GET_ALL_NOTIF = `${ENDPOINT}/notification/affiliator/get-all`;
export const READ_BY_ID = `${ENDPOINT}/notification/affiliator/read-by-id/`;
export const READ_ALL = `${ENDPOINT}/notification/affiliator/read-all`;
export const DELETE_NOTIF = `${ENDPOINT}/notification/affiliator/delete-by-id/`;
export const DELETE_ALL_NOTIF = `${ENDPOINT}/notification/affiliator/delete-all`;

// REGISTER UMKM
export const REGISTER_UMKM = `${ENDPOINT}/register/register-pumkm`;
export const SEARCH_CITY = `${ENDPOINT}/register/search-city`;

// AUTH SERVICE
const BASE_URL_AUTH = environment_auth.base_url;
export const STORE_PIN = `${BASE_URL_AUTH}/api/v1/user/setting/pin/store`;
export const UPDATE_PIN_NEW = `${BASE_URL_AUTH}/api/v1/user/setting/pin/update`;
export const VERIF_PIN_NEW = `${BASE_URL_AUTH}/api/v1/user/secured/verify-pin`;
