import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css'],
})
export class PrivacyPolicyComponent implements OnInit {
  title: string = 'Kebijakan Privasi';
  titlePerolehan: string = 'Perolehan Data'
  titlePenggunaan: string = 'Penggunaan Data'
  titleJaminan: string = 'Jaminan Keamanan Data'
  constructor() {}

  ngOnInit() {}
}
