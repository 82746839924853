<app-header></app-header>
<section class="Kebijakan">
  <div class="container">
    <div class="title">
      <span>{{title}}</span>
    </div>
    <div class="greeting">
      <p>
        <b>PT. Kampung Marketerindo Berdaya</b> yang kemudian disebut “Komerce” atau “Kami” adalah pemilik konten,
        grafis, dan
        pengembang dari situs dan platform Affiliate Komerce. Kami berkomitmen untuk melindungi dan menghargai privasi
        dan
        informasi pribadi maupun perusahaan yang kami miliki.
      </p>
      <p>
        Anda wajib membaca dan memahami Kebijakan Privasi kami sebelum menggunakan platform Affiliate Komerce. Dengan
        menggunakan platform Affiliate Komerce, kami menganggap anda telah setuju dengan Kebijakan Privasi kami.
      </p>
    </div>
    <div class="content-perolehan mt-3">
      <div class="title-content">{{titlePerolehan}}</div>
      <div class="description-content mt-2">
        <p>Affiliate Komerce memperoleh data pribadi maupun perusahaan melalui :</p>
        <ul>
          <li>Pencatatan data secara otomatis</li>
          <li>Registrasi online</li>
          <li>Platform Layanan Komerce lain</li>
          <li>Sumber informasi lainnya</li>
        </ul>
      </div>
    </div>
    <div class="content-penggunaan mt-3">
      <div class="title-content">{{titlePenggunaan}}</div>
      <div class="description-content mt-2">
        <p>Data-data yang telah diperloleh akan digunakan untuk :</p>
        <ul>
          <li>Melakukan komunikasi dalam rangka penawaran jasa layanan Komerce</li>
          <li>Melakukan komunikasi koordinasi antar pihak Affiliator Komerce dengan partner</li>
          <li>Menyebarkan informasi dan promosi Affiliate Komerce</li>
          <li>Sebagai database kontak affiliator, calon affiliator, dan ex-affiliatior</li>
          <li>Pengisian data pada form Affiliate Komerce dan profile</li>
          <li>Registrasi online</li>
          <li>Sumber informasi lainnya</li>
        </ul>
      </div>
    </div>
    <div class="content-jaminan mt-3">
      <div class="title-content">{{titleJaminan}}</div>
      <div class="description-content mt-2">
        <p>
          Affiliate Komerce menjamin kerahasiaan dan keamanan data pribadi maupun bisnis
          dari pihak-pihak lain yang akan menggunakan untuk tindakan yang merugikan
          pribadi atau bisnis. Affiliate Komerce akan menginformasikan terlebih dahulu dan
          meminta persetujuan pemilik data jika akan melakukan publikasi data pribadi atau
          bisnis pengguna jasa.
        </p>
        <p>
          Namun Affiliate Komerce tidak bertanggung jawab apabila kebocoran data
          dilakukan karena tindakan pemilik data atau pengguna jasa tanpa arahan dari pihak
          Affiliate Komerce.
        </p>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>