import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { IconsModule } from './modules/shared/icon.module';
import { OtpPipe } from './pipes/otp.pipe';
import { authInterceptorProviders } from './interceptors/http.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { LoaderComponent } from './modules/shared/components/loader/loader.component';
import {ToastService} from "./services/toast.service";
import {SharedModule} from "./modules/shared/shared.module";
import {ErrorInterceptor} from "./interceptors/error.interceptor";
import {NgxDaterangepickerMd} from "ngx-daterangepicker-material";
import * as dayjs from "dayjs";
import { TermAndConditionComponent } from './modules/term-and-condition/term-and-condition.component';
import { PrivacyPolicyComponent } from './modules/privacy-policy/privacy-policy.component';
import { SharedService } from './modules/shared/shared.service';
import { CustomCurrencyDirective } from './directives/currency.directive';
import { HeaderComponent } from './modules/component/header/header.component';
import { FooterComponent } from './modules/component/footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    TermAndConditionComponent,
    PrivacyPolicyComponent,
    CustomCurrencyDirective,
    HeaderComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    IconsModule,
    HttpClientModule,
    SharedModule,
    NgxDaterangepickerMd.forRoot(),
    NgbPopoverModule,
  ],
  providers: [
    OtpPipe,
    ToastService,
    authInterceptorProviders,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    SharedService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
