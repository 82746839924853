<div class="table table-responsive">
  <table class="table">
    <thead>
      <tr>
        <th *ngFor="let item of header" scope="col">{{item}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of dataKomship; let i=index">
        <th scope="row">{{i+1}}</th>
        <td>
          <div>
            {{item?.member_name ?? '-'}}
            <div class="subtiitle">{{item?.member_email ?? '-'}}</div>
          </div>
        </td>
        <td>
          {{item?.join_date ?? '-'}}
        </td>
        <td>
          <div>
            {{item?.performance ?? '-'}}
          <div class="subtiitle2">Order</div>
        </div>

      </td>
        <td>{{item?.total_shipping ?? 0 | currency:'Rp'}}</td>
        <td>{{item?.commission ?? 0 | currency:'Rp'}}</td>
      </tr>
    </tbody>
  </table>
</div>



<!-- Table Boostr -->
<div class="table table-responsive">
  <table class="table">
    <thead>
      <tr>
        <th *ngFor="let item of header" scope="col">{{item}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of dataBoostr; let i=index">
        <th scope="row">{{i+1}}</th>
        <td>
          <div>
            {{item?.member_name ?? '-'}}
            <div class="subtiitle">{{item?.member_email ?? '-'}}</div>
          </div>
        </td>
        <td>
          {{item?.upgrade_date ?? '-'}}
        </td>
        <td>
          <div>
            {{item?.package_description ?? '-'}}
        </div>

      </td>
        <td>{{item?.package_price ?? 0 | currency:'Rp'}}</td>
        <td>{{item?.commission ?? 0 | currency:'Rp'}}</td>
      </tr>
    </tbody>
  </table>
</div>
