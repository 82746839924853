
<form [action]="">
    <div class="d-flex justify-content-end w-100 p-2">
        <img (click)="handleClose($event)" style="cursor: pointer;" src="assets/images/close-modal.svg" alt="">
    </div>
    <div class="p-2" *ngIf="!isSms && !isWhatsapp">
        <div class="d-flex flex-column gap-3 justify-content-center align-items-center my-2">
            <div class="d-flex flex-column gap-3 ps-4 pe-4">
                <div class="title text-center">Pilih Metode Verifikasi</div>
                <div class="desc text-center p-2">
                    Pilih salah satu metode dibawah ini untuk mendapatkan kode verifikasi
                </div>
            </div>
        </div>
        <div class="d-flex flex-column gap-3 ps-4 pe-4 my-2">
            <button class="sms-otp desc p-2" (click)="sendSms($event)" style="cursor: pointer;" disabled>
                <div class="row ps-2 pe-2">
                    <div class="col-2 text-center p-0" style="align-self: center;color: #FBBC05;">
                        <i-feather class="me-2" style="color: #C2C2C2;" name="mail"></i-feather>
                    </div>
                    <div class="col-10 p-0 text-left">
                        <strong>SMS kode OTP</strong>
                        <div><span>{{detailProfile.secret_no}}</span></div>
                    </div>
                </div>
            </button>
            <!-- <div class="sms-otp desc p-2" (click)="sendSms($event)" style="cursor: pointer;">
                <div class="row ps-2 pe-2">
                    <div class="col-2 text-center p-0" style="align-self: center;color: #FBBC05;">
                        <i-feather class="me-2" name="mail"></i-feather>
                    </div>
                    <div class="col-10 p-0">
                        <strong>SMS kode OTP</strong>
                        <div><span>{{detailProfile?.secret_no}}</span></div>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="d-flex flex-column gap-3 ps-4 pe-4 my-2 mb-3">
            <div class="wa-otp desc p-2" (click)="sendWhatsapp($event)" style="cursor: pointer;">
                <div class="row ps-2 pe-2">
                    <div class="col-2 text-center p-0" style="align-self: center;">
                        <img src="https://storage.googleapis.com/komerce/assets/icons/whatsapp.svg" alt="">
                    </div>
                    <div class="col-10 p-0">
                        <strong>Pesan Whatsapp ke</strong>
                        <div><span>{{detailProfile.secret_no}}</span></div>
                        <div>
                            <small *ngIf="isCheckWhatsapp === true" class="text-success">Nomor ini terhubung ke
                                Whatsapp</small>
                            <small *ngIf="isCheckWhatsapp === false" class="text-danger">Nomor ini tidak terhubung ke
                                Whatsapp</small>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="isSendOtp === true" class="text-danger">
                <small>Failed to sent otp</small>
            </div>
        </div>
    </div>
    <div class="p-2" *ngIf="isSms">
        <div class="d-flex flex-column gap-3 justify-content-center align-items-center my-2">
            <div class="d-flex flex-column gap-3 ps-4 pe-4">
                <div class="title text-center">Verifikasi SMS</div>
                <div class="desc text-center">
                    <i-feather style="color: #FBA63C;" name="mail"></i-feather>
                </div>
                <div class="title text-center">Masukkan Kode Verifikasi</div>
                <div class="desc text-center">
                    Kode verifikasi telah dikirim melalui SMS ke nomor
                </div>
                <div class="desc text-center">
                    {{detailProfile.secret_no}}
                </div>
                <div class="my-3">
                    <div class="otp-container text-center {{setting.wrapperClass}}" id="c_{{componentKey}}"
                        *ngIf="otpForm?.controls" [ngStyle]="setting.wrapperStyles">
                        <input type="password" numberOnly [disabledNumberOnly]="!setting.numbersOnly"
                            [ngStyle]="setting.inputStyles" maxlength="1" class="otp-input {{setting.inputClass}}"
                            autocomplete="off" *ngFor="let item of otpForm?.controls | otp; let i = index"
                            [formControl]="$any(otpForm.controls[item])" id="otp_{{i}}_{{componentKey}}"
                            (keyup)="keyUp($event, i); checkOtpSms($event)" [(ngModel)]="otpSms[i]"
                            (keydown)="onKeyDown($event)" required>
                    </div>
                    <div class="my-2" *ngIf="isErrorMessage === true">
                        <small class="text-danger">Kode yang kamu masukkan salah</small>
                    </div>
                </div>
                <div class="my-3">
                    <div class="timeDown text-center" *ngIf="timeDown">
                        <span>Mohon tunggu dalam <strong>{{count}} detik</strong> untuk kirim ulang</span>
                    </div>
                    <div class="resendOtp text-center" *ngIf="resendTryOtp">
                        <span>Tidak menerima kode? <span (click)="resendOtpSms($event)"
                                style="color: #FBA63C;cursor: pointer;">
                                Kirim ulang</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="p-2" *ngIf="isWhatsapp">
        <div class="d-flex flex-column gap-1 justify-content-center align-items-center my-2">
            <div class="d-flex flex-column gap-1 ps-4 pe-4">
                <div class="title text-center">Verifikasi Whatsapp</div>
                <div class="desc text-center">
                    <img src="https://storage.googleapis.com/komerce/assets/icons/whatsapp.svg" alt="">
                </div>
                <div class="title text-center">Masukkan Kode Verifikasi</div>
                <div class="desc text-center">
                    Kode verifikasi telah dikirim melalui Whatsapp ke nomor
                </div>
                <div class="desc text-center">
                    {{detailProfile.secret_no}}
                </div>
                <div class="my-3">
                    <div class="otp-container text-center {{setting.wrapperClass}}" id="c_{{componentKey}}"
                        *ngIf="otpForm?.controls" [ngStyle]="setting.wrapperStyles">
                        <input type="password" numberOnly [disabledNumberOnly]="!setting.numbersOnly"
                            [ngStyle]="setting.inputStyles" maxlength="1" class="otp-input {{setting.inputClass}}"
                            autocomplete="off" *ngFor="let item of otpForm?.controls | otp; let i = index"
                            [formControl]="$any(otpForm.controls[item])" id="otp_{{i}}_{{componentKey}}"
                            (keyup)="keyUp($event, i); checkOtpWhatsapp($event)" [(ngModel)]="otpWhatsapp[i]"
                            (keydown)="onKeyDown($event)" required>
                    </div>
                    <div class="my-2" *ngIf="isErrorMessage === true">
                        <small class="text-danger">Kode yang kamu masukkan salah</small>
                    </div>
                </div>
                <div class="my-3">
                    <div class="timeDown text-center" *ngIf="timeDown">
                        <span>Mohon tunggu dalam <strong>{{count}} detik</strong> untuk kirim ulang</span>
                    </div>
                    <div class="resendOtp text-center" *ngIf="resendTryOtp">
                        <span>Tidak menerima kode? <span (click)="resendOtpWhatsapp($event)"
                                style="color: #FBA63C;cursor: pointer;">
                                Kirim ulang</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>