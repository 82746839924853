<footer class="text-center text-lg-start text-muted pt-5">
  <div class="container">
      <div class="logo-komerce">
        <img src="../../../../assets/images/footer/komerce.svg" alt="logo-komship" width="170px" />
      </div>
  </div>

  <div class="col-content">
    <div class="container text-center text-md-start mt-5">
      <div class="row">
        <div class="col-xl-5 col-md-4 col-12 mx-auto mb-4 p-0">
          <div class="row row-pertama d-flex mb-3">
            <div class="col-1 col-logo p-0">
              <i-feather name="map-pin"></i-feather>
            </div>
            <div class="col-10 col-ket">
              <span>
                Head Office Komerce Jln. Sawah Tengah No. 3 Desa Tunjungmuli
                Kecamatan Karangmoncol Kabupaten Purbalingga (53355)
              </span>
            </div>
          </div>
          <div class="row row-pertama d-flex mb-3">
            <div class="col-1 col-logo p-0">
              <img src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/Whatsapp.svg" alt="whatsapp" />
            </div>
            <div class="col-10 col-ket">
              <span> +62 838 4057 2230 </span>
            </div>
          </div>
          <div class="row row-pertama d-flex mb-3">
            <div class="col-1 col-logo p-0">
              <i-feather name="mail"></i-feather>
            </div>
            <div class="col-10 col-ket">
              <span> contact@komerce.id </span>
            </div>
          </div>
          <!-- <div class="row row-terahir d-flex mb-3">
            <div class="col-xl-6 col-md-6 col-sm-6 col-6 col-sk">
              <a routerLink="/term-condition">Terms and Conditions</a>
            </div>
            <div class="col-xl-6 col-md-6 col-6 col-kp">
              <a routerLink="/privacy">Privacy Policy</a>
            </div>
          </div> -->
        </div>
        <div class="col-xl-4 col-md-4 col-12 mx-auto mb-4">
          <div class="judul-ket mb-3">
            <span> Layanan </span>
          </div>
          <div class="isi-judul">
            <ul class="menu-items">
              <li class="mb-3">
                <img src="https://storage.googleapis.com/komerce/assets/logo/komship.svg" width="110px" alt="komship" />
              </li>
              <li class="mb-3">
                <img src="https://storage.googleapis.com/komerce/assets/logo/komtim.svg" width="110px" alt="komtim" />
              </li>
              <li class="mb-3">
                <img src="https://storage.googleapis.com/komerce/assets/logo/kompack.svg" width="110px" alt="kompack" />
              </li>
              <li class="mb-3">
                <img src="https://storage.googleapis.com/komerce/assets/logo/komclass.svg" width="110px" alt="komclass" />
              </li>
              <li class="mb-3">
                <img src="../../../../assets/images/footer/boostr.svg" width="110px" alt="boostr" />
              </li>
            </ul>
          </div>
        </div>
        <div class="col-xl-3 col-md-4 col-12 mx-auto mb-4">
          <div class="row">
            <div class="logo-1">
              <div class="judul-ket mb-3">
                <span> Find Out Our Latest News </span>
              </div>
              <div class="logo-medsos">
                <div class="row row-medsos">
                  <div class="col-2">
                    <a href="https://www.instagram.com/komerceid/" target="_blank">
                      <i-feather name="instagram" style="color: #FFFF;"></i-feather>
                    </a>
                  </div>
                  <div class="col-2">
                    <a href="https://www.facebook.com/komerce.official/" target="_blank">
                      <i-feather name="facebook" style="color: #FFFF;"></i-feather>
                    </a>
                  </div>
                  <div class="col-2">
                    <a href="https://www.linkedin.com/company/komerceid/" target="_blank">
                      <i-feather name="linkedin" style="color: #FFFF;"></i-feather>
                    </a>
                  </div>
                  <div class="col-2">
                    <a href="https://t.me/komerce" target="_blank">
                      <img src="https://storage.googleapis.com/komerce/assets/svg/telegram-alt.svg" alt="">
                    </a>
                  </div>
                  <div class="col-2">
                    <a href="https://www.youtube.com/@komerceid" target="_blank">
                      <i-feather name="youtube" style="color: #FFFF;"></i-feather>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="logo-2">
              <div class="judul-ket mb-2 mt-4">
                <span> Collaboration Partners </span>
              </div>
              <div class="logo-medsos2">
                <div class="row row-medsos2 p-1">
                  <div class="col-3 mt-1 mb-1">
                    <img src="https://storage.googleapis.com/komerce/assets/svg/CloudHost.svg" alt="logo-1" />
                  </div>
                  <div class="col-3 mt-1 mb-1">
                    <img src="https://storage.googleapis.com/komerce/assets/svg/Zahir.svg" alt="logo-2" />
                  </div>
                  <div class="col-3 mt-1 mb-1">
                    <img src="https://storage.googleapis.com/komerce/assets/svg/Kirim.svg" alt="logo-3" />
                  </div>
                  <div class="col-3 mt-1 mb-1">
                    <img src="https://storage.googleapis.com/komerce/assets/svg/JNE.svg" alt="logo-4" />
                  </div>
                  <div class="col-3 mt-1 mb-1">
                    <img src="https://storage.googleapis.com/komerce/assets/svg/Sicepat.svg" alt="logo-5" />
                  </div>
                  <div class="col-3 mt-1 mb-1">
                    <img src="https://storage.googleapis.com/komerce/assets/svg/IdExpress.svg" alt="logo-6" />
                  </div>
                  <div class="col-3 mt-1 mb-1">
                    <img src="https://storage.googleapis.com/komerce/assets/svg/SAP.svg" alt="logo-7" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row row-copyright">
      <div class="col-xl-6 col-md-6 col-12 text-copyLeft">
        <strong> Copyright © 2023 Komerce. All Rights Reserved </strong>
      </div>
      <div class="col-xl-6 col-md-6 col-12 text-copyRight">
        <strong> Trademark of PT Kampung Marketerindo Berdaya </strong>
      </div>
    </div>
  </div>
</footer>