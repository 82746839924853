import { NavigationEnd, Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavbarInterFace } from 'src/app/models/navbar.model';
import { ModalBuatPinComponent } from 'src/app/modules/pengaturan/components/modal-buat-pin/modal-buat-pin.component';
import { ModalMaintananceComponent } from 'src/app/modules/shared/components/modal-maintanance/modal-maintanance.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export default class SidebarComponent implements OnInit {
  @Output() isActive = new EventEmitter();
  @Input() active: boolean = false;
  activeButton: string = 'Dashboard';
  constructor(private elementRef: ElementRef, private modalService: NgbModal, private route: Router) {
    route.events.subscribe((val) => {
      const nav = val as NavigationEnd
      
      if(nav.url === '/dashboard'){
        this.activeButton = 'Dashboard'
      }else if(nav.url === '/riwayat-komisi/1' || nav.url === '/riwayat-komisi/2') {
        this.activeButton = 'Riwayat Komisi'
      }else if(nav.url === '/pengaturan/profile'){
        this.activeButton = 'Profile'
      }else if(nav.url === '/pengaturan/rekening-bank'){
        this.activeButton = 'Rekening Bank'
      }else if(nav.url === '/'){
        this.activeButton = 'Dashboard'
      }
      if (!this.active) {
        if (nav.url === '/pengaturan/profile') {
          this.activeButton = 'Pengaturan';
        }
        if (nav.url === '/pengaturan/rekening-bank') {
          this.activeButton = 'Pengaturan';
        }
      }
  });

  }
  inside = false;
  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.isActive.emit(false);
    }
  }
  ngOnInit() {
  }

  setNavActive(data: any): void {
    this.isActive.emit(false);
  }
  
}
