import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-term-and-condition',
  templateUrl: './term-and-condition.component.html',
  styleUrls: ['./term-and-condition.component.css'],
})
export class TermAndConditionComponent implements OnInit {
  title: string = 'Syarat dan Ketentuan';
  titleDefinisi: string = '1. Definisi';
  titleJasa: string = '2. Pengguna Jasa';
  titleSyarat: string = '3. Syarat Pengguna Platform';
  titleKewajiban: string = '4. Kewajiban Affiliator';
  titleKetentuan: string = '5. Ketentuan Lainnya';
  constructor() {}

  ngOnInit() {}
}
