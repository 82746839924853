import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OnboardingModel } from 'src/app/models/onboarding.model';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {

  @Input() data!: OnboardingModel[]
  selectIndicator: number = 0
  isModalVisible: boolean = true;
  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    const isModalShown = localStorage.getItem('isModalShown');
    if (isModalShown === 'false') {
      this.isModalVisible = false;
      this.activeModal.dismiss();
    }
  }

  handleNext():void{
    this.selectIndicator = this.data.length-1 > this.selectIndicator ? this.selectIndicator + 1 : this.selectIndicator
  }

  handleBack():void{
    this.selectIndicator = 0 < this.selectIndicator ? this.selectIndicator - 1 : this.selectIndicator
  }
  handleFinish() {
    this.activeModal.dismiss();
    this.isModalVisible = false;
    localStorage.setItem('isModalShown', 'false');
  }
}
