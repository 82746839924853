<div ngbDropdown #myDrop="ngbDropdown">
  <div class="form-control d-flex flex-row gap-3">
    <input ngbDropdownToggle type="text" [value]="selectDate" style="outline: none !important; border: none !important;" class="w-100">
    <i-feather name="calendar" class="text-primary"></i-feather>
  </div>
  <div ngbDropdownMenu>
    <div class="d-flex flex-row gap-1">
      <div class="d-flex flex-column gap-2 choose py-3">
        <button [ngClass]="{'btn-primary': !isShowYears, 'btn-link': isShowYears}" class="btn custom-choose " (click)="toggleShowMonth()">Bulan</button>
        <button [ngClass]="{'btn-primary': isShowYears, 'btn-link': !isShowYears}" class="btn custom-choose" (click)="toggleShowYears()">Tahun</button>
      </div>
      <div class="w-100 month-picker">
        <div class="card">
          <div class="card-header">
            <div class="d-flex align-items-center justify-content-center">

              <button class="btn mr-auto" (click)="decrement()">
                <i class="fa fa-chevron-left"></i>
              </button>

              <div style="font-size: 24px" class="mx-2" >
                {{model.selectedYearText}}
              </div>

              <button class="btn ml-auto" (click)="increment()">
                <i class="fa fa-chevron-right"></i>
              </button>

            </div>
          </div>

          <div class="card-body">
            <div class="month-picker-body">

              <div class="row" *ngIf="!isShowYears">
                <div class="col col-4" *ngFor="let month of model.months; let i = index">
                  <div class="month-picker-cell">
                    <button class="btn btn-block"
                            [ngClass]="{'btn-primary': isSelectedMonth(i)}"
                            [disabled]="isDisabled(i)"
                            (click)="selectMonth(i); myDrop.close()">
                      <small>{{month.slice(0,3)}}</small>
                    </button>
                  </div>
                </div>
              </div>


              <div class="row" *ngIf="isShowYears">
                <div class="col col-4" *ngFor="let year of years; let i = index">
                  <div class="month-picker-cell">
                    <button class="btn btn-block"
                            [ngClass]="{'btn-primary': isSelectedYear(year)}"
                            [disabled]="isDisabledYear(year)"
                            (click)="selectYear(year)"
                    >
                      <small>{{year}}</small>
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
