import { ModalMaintananceComponent } from './components/modal-maintanance/modal-maintanance.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from './icon.module';
import { PaginiationComponent } from './components/paginiation/paginiation.component';
// import { PaginiationDashboardComponent } from './components/paginiation-dashboard/paginiation-dashboard.component';
import { TableComponent } from './components/table/table.component';
import { EmptyDataTableComponent } from './components/empty-data-table/empty-data-table.component';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { OtpPipe } from '../../pipes/otp.pipe';
import { OtpInputComponent } from './components/otp-input/otp-input.component';
import { NumberOnly } from '../../directives/number-only.directive';
import { CounterDirective } from '../../directives/timer.directive';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {ToastComponent} from "./components/toast/toast.component";
import {DateMonthPickerComponent} from "./components/date-month-picker/date-month-picker.component";
import { OtpAddRekeningComponent } from './components/otp-add-rekening/otp-add-rekening.component';



@NgModule({
  declarations: [
    PaginiationComponent,
    // PaginiationDashboardComponent,
    EmptyDataTableComponent,
    TableComponent,
    OnboardingComponent,
    NumberOnly,
    CounterDirective,
    OtpInputComponent,
    OtpPipe,
    ConfirmationDialogComponent,
    DateRangePickerComponent,
    ToastComponent,
    ModalMaintananceComponent,
    DateMonthPickerComponent,
    OtpAddRekeningComponent
  ],
  imports: [
    CommonModule,
    IconsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  exports: [
    IconsModule,
    TableComponent,
    DateMonthPickerComponent,
    EmptyDataTableComponent,
    PaginiationComponent,
    OnboardingComponent,
    CommonModule, NumberOnly, CounterDirective, OtpInputComponent, OtpPipe,
    ConfirmationDialogComponent,
    DateRangePickerComponent,
    ToastComponent,
    ModalMaintananceComponent,
    OtpAddRekeningComponent
  ]
})
export class SharedModule { }
