<div class="d-flex flex-column gap-3 justify-content-center align-items-center">
  <div class="d-flex justify-content-end w-100 p-2">
    <img (click)="handleClose($event)" style="cursor: pointer;" src="assets/images/close-modal.svg" alt="">
  </div>
  <div class="d-flex mb-4 flex-column justify-content-center gap-3 ps-5 pe-5">
    <img height="150px" src="assets/images/komisi/empty-table.svg" alt="">
    <div class="title text-center">
      Website under maintenance...
    </div>
    <div class="desc text-center">
     <p>Our Website is currently undergoing scheduled maintenance. We should be back shorlty. Thank you for patience</p>
    </div>
    <button (click)="handleClose($event)" class="btn btn-primary w-100">Ok</button>
  </div>

 </div>
