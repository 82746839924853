import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';
import { TokenStorageService } from './token-storage.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  email: any;
  private getEmailVerif: string = '';
  private formFilledStatus = new Subject<boolean>();
  formFilledStatus$ = this.formFilledStatus.asObservable();
  private isDisabledCheckBox: boolean = true;
  baseService(ACCESS_LIST: string) {
    throw new Error('Method not implemented.');
  }
  constructor(
    private httpClient: HttpClient,
    private storageService: TokenStorageService
  ) { }
  
  updateFormFilledStatus(status: boolean) {
    this.formFilledStatus.next(status);
  }

  setDataCheck(data: boolean) {
    this.isDisabledCheckBox = data
  }
  getDataCheck(): boolean {
    return this.isDisabledCheckBox
  }

  setData(data: string) {
    this.getEmailVerif = data;
  }
  getData(): string {
    return this.getEmailVerif;
  }

  public getDataWithToken(
    url: string,
    paramsRequest?: any,
    requestHeaders?: any
  ): Observable<any> {
    var headers = new HttpHeaders({
      ...requestHeaders,
    });

    var params = this.generateHttpParam(paramsRequest);
    return this.httpClient.get(url, { headers, params });
  }

  public postDataWithToken(
    url: string,
    requestBody?: any,
    responseType?: string
  ): Observable<any> {
    const headers = new HttpHeaders({});
    return (
      this.httpClient
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .post(url, requestBody, { headers, responseType })
    );
  }

  public putDataWithToken(
    url: string,
    requestBody?: any,
    requestParam?: any
  ): Observable<any> {
    const headers = new HttpHeaders({});

    return this.httpClient.put(url, requestBody, {
      headers,
      params: requestParam,
    });
  }

  // public postFileWithToken(url: string, requestBody: any): Observable<any> {
  //   const headers = new HttpHeaders({
  //     Authorization:
  //       'Bearer ' + this.storageService.getLoginCredential()?.token,
  //     'X-REQUEST-ID': uuidv4(),
  //     platform: 'Web',
  //   });

  //   return this.httpClient.post(url, requestBody, {
  //     headers,
  //     responseType: 'blob' as 'json',
  //   });
  // }

  public deleteDataWithToken(
    url: string,
    requestHeaders?: any
  ): Observable<any> {
    var headers = new HttpHeaders({
      ...requestHeaders,
    });

    return this.httpClient.delete(url, { headers });
  }

  public postDataWithAuth(url: string, requestBody: any): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Basic ${btoa(environment.durian_pay.api_key + ':')}`,
    });

    return this.httpClient.post(url, requestBody, { headers });
  }

  public getDataWithAuth(url: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Basic ${btoa(environment.durian_pay.api_key + ':')}`,
    });

    return this.httpClient.get(url, { headers });
  }

  public generateHttpParam(param: any): HttpParams {
    let result = new HttpParams();

    if (param) {
      Object.keys(param).forEach((key) => {
        if (
          param[key] !== null &&
          param[key] !== undefined &&
          param[key] !== ''
        ) {
          result = result.set(key, param[key]);
        }
      });
    }

    return result;
  }
}
