<div class="d-flex flex-column text-center">
  <div
    class="otp-container {{ setting.wrapperClass }}"
    id="c_{{ componentKey }}"
    *ngIf="otpForm?.controls"
    [ngStyle]="setting.wrapperStyles"
  >
    <input
      type="password"
      numberOnly
      [disabledNumberOnly]="!setting.numbersOnly"
      [ngStyle]="setting.inputStyles"
      maxlength="1"
      class="otp-input {{ setting.inputClass }}"
      autocomplete="off"
      *ngFor="let item of otpForm?.controls | otp; let i = index"
      [formControl]="$any(otpForm.controls[item])"
      id="otp_{{ i }}_{{ componentKey }}"
      (keyup)="keyUp($event, i)"
    />
  </div>
  <span *ngIf="isZeroFirst" class="text-danger"
    >PIN tidak boleh diawali dengan 0</span
  >
</div>
<!-- <ng-container counter [counter]="$any(setting.timer)" (value)="onCounterChange($event)">
  <div>
      <button class="btn {{setting.btnClass}}" [disabled]="counter != 0" (click)="ressendOtp()">
          Resend OTP
          <span *ngIf="counter != 0">
              <ng-container *ngIf="!setting.timerType">
                  in {{ counter }} seconds.
              </ng-container>
              <ng-container *ngIf="setting.timerType">
                  in {{ formatSecsToMins(counter) }} minutes.
              </ng-container>
          </span>
      </button>
  </div>
</ng-container> -->
