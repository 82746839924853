<app-header></app-header>
<section class="terms">
  <div class="container">
    <div class="title">
      <span>{{title}}</span>
    </div>
    <div class="greeting">
      <p>Selamat datang di platform Affiliate Komerce</p>
      <span>
        Syarat dan ketentuan ini dapat mempengaruhi hak dan kewajiban pengguna jasa Komerce dan di bawah ketentuan hukum yang
        berlaku. Syarat dan ketentuan di bawah ini mengatur penggunaan layanan jasa yang ditawarkan oleh <b>PT.Kampung Marketerindo
        Berdaya (Affiliate Komerce)</b> mulai dari layanan Komship, Komtim, Kompack, Komclass, hingga Boostr.
      </span> <br>
      <span>
        Dengan menggunakan layanan kami, Anda dianggap telah membaca, mengerti, dan menyetujui serta terikat dan tunduk dengan
        segala syarat dan ketentuan yang berlaku di sini. Syarat dan ketentuan ini dapat diubah oleh kami dengan pemberitahuan
        terlebih dahulu.
      </span>
    </div>
    <div class="content-definisi mt-3">
      <div class="title-content">{{titleDefinisi}}</div>
      <div class="description-content mt-1">
        <div class="container">
          <div class="row">
            <div class="col-2 p-0">1.1 "Kami"</div>:
            <div class="col-9">PT. Kampung Marketerindo Berdaya selaku pemilik Komerce dan pengelola platform Affiliate Komerce
            </div>
          </div>
          <div class="row">
            <div class="col-2 p-0">1.2 "Anda"</div>:
            <div class="col-9">Orang yang mengakses situs atau pengguna platform Affiliate Komerce</div>
          </div>
          <div class="row">
            <div class="col-2 p-0">1.3 "Affiliator"</div>:
            <div class="col-9">Orang yang menggunakan jasa Affiliate Komerce</div>
          </div>
          <div class="row">
            <div class="col-2 p-0">1.4 “Partner”</div>:
            <div class="col-9">Orang yang menggunakan jasa layanan Komerce</div>
          </div>
          <div class="row">
            <div class="col-2 p-0">1.5 “Member”</div>:
            <div class="col-9">Orang yang menggunakan jasa layanan Komerce dengan Kode Kupon dari Affiliator</div>
          </div>
          <div class="row">
            <div class="col-2 p-0">1.6 "UMKM"</div>:
            <div class="col-9">Bisnis yang dijalankan oleh perseorangan, rumah tangga, atau badan usaha yang memenuhi kriteria
              yang ditetapkan oleh Undang-Undang No. 20 tahun 2008</div>
          </div>
          <div class="row">
            <div class="col-2 p-0">1.7 "Pebisnis Online"</div>:
            <div class="col-9">Perusahaan atau perseorangan yang menjalankan bisnisnya melalui sistem digital atau elektronik
            </div>
          </div>
          <div class="row">
            <div class="col-2 p-0">1.8 "Data Pribadi"</div>:
            <div class="col-9">Data-data pribadi Pengguna yang diberikan kepada pihak Komerce yang tidak terbatas pada meliputi
              nama, jenis kelamin, nomor telepon, alamat lengkap, informasi bisnis sebagaimana yang diminta dalam proses
              pemberdayaan Talent.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-jasa mt-3">
      <div class="title-content">{{titleJasa}}</div>
      <div class="description-content mt-1">
        <p>
          Dengan menjadi pengguna platform Affiliate Komerce, maka Anda dianggap telah mampu untuk memenuhi syarat dan ketentuan
          sebagai berikut :
        </p>
        <div class="container p-0">
          <div class="d-flex">
            <span class="pe-3">2.1</span> &nbsp;
            <span class="">
              Anda tidak diperkenankan untuk menggunakan situs Affiliate Komerce, sebagian atau keseluruhan konten dari situs
              Affiliate Komerce untuk keperluan pribadi. Hal ini dapat diartikan bahwa Anda hanya diperkenankan menggunakan situs,
              aplikasi, dan konten dari Affiliate Komerce untuk keperluan individu atau bisnis yang menyediakan pengelolaan
              transaksi barang.
            </span>
          </div>
          <div class="d-flex">
            <span class="pe-3">2.2</span>
            <span class="">
              Penyalahgunaan situs Affiliate Komerce untuk kepentingan pribadi maupun kelompok yang melanggar hukum akan ditindak
              sesuai dengan peraturan yang diatur dalam Undang-Undang yang berlaku di Republik Indonesia.
            </span>
          </div>
          <div class="d-flex">
            <span class="pe-3">2.3</span>
            <span class="">
              Pengguna jasa pengelola transaksi Affiliate Komerce yang terbukti menyalahgunakan kerjasama dengan Komerce untuk
              tindakan yang melanggar hukum akan ditindak sesuai dengan peraturan perundang-undangan yang berlaku.
            </span>
          </div>
          <div class="d-flex">
            <span class="pe-3">2.4</span>
            <span class="">
              Pengguna jasa dan situs Affiliate Komerce tidak diperkenankan untuk menggunakan situs dan/atau aplikasi untuk tindakan
              yang mengganggu atau melanggar hak orang lain.
            </span>
          </div>
          <div class="d-flex">
            <span class="pe-3">2.5</span>
            <span class="">
              Pengguna situs Affiliate Komerce tidak diperkenankan untuk mengubah data dan memodifikasi sebagian atau keseluruhan isi
              dari situs maupun aplikasi tanpa persetujuan dari pihak Affiliate Komerce.
            </span>
          </div>
          <div class="d-flex">
            <span class="pe-3">2.6</span>
            <span class="">
              Pengguna platform Affiliate Komerce disebut Affiliator.
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="content-syarat mt-3">
      <div class="title-content">{{titleSyarat}}</div>
      <div class="description-content mt-1">
        <div class="container p-0">
          <div class="d-flex">
            <span class="pe-3">3.1</span> &nbsp;
            <span>
              Pengguna platform Affiliate Komerce merupakan individu atau badan usaha yang bergerak di bidang bisnis online baik
              Business-to-Business maupun Business-to-Consumer (B2C).
            </span>
          </div>
          <div class="d-flex">
            <span class="pe-3">3.2</span>
            <span>
              Pengguna platform Affiliate Komerce harus dapat memberikan informasi dan perusahaan dengan sebenar-benarnya.
            </span>
          </div>
          <div class="d-flex">
            <span class="pe-3">3.3</span>
            <span>
              Apabila di kemudian hari ditemukan data yang tidak sesuai maka pihak Komerce berhak untuk mengakhiri kerjasama.
            </span>
          </div>
          <div class="d-flex">
            <span class="pe-3">3.4</span>
            <span>
              Permohonan penarikan komisi dapat dilakukan kapan saja dan akan diproses saat jam kerja kantor (Senin - Jumat pukul
              08.00 - 16.00 WIB).
            </span>
          </div>
          <div class="d-flex">
            <span class="pe-3">3.5</span>
            <span>
              Nama pada rekening bank harus sama dengan nama pada profil akun yang terdaftar.
            </span>
          </div>
          <div class="d-flex">
            <span class="pe-3">3.6</span>
            <span>
              Komisi afiliasi dihitung setelah member berhasil melakukan transaksi pada setiap produk yang terdaftar di Komerce. 
            </span>
          </div>
          <div class="d-flex">
            <span class="pe-3">3.7</span>
            <span>
              Anda tidak dapat melakukan klaim partner lain sebagai member Anda jika Anda lupa atau tidak memberi link afiliasi
              ataupun kode referral Anda.
            </span>
          </div>
          <div class="d-flex">
            <span class="pe-3">3.8</span>
            <span>
              Apabila terdeteksi adanya kesamaan data antara member dan affiliator yang mengakibatkan kecurigaan maka akan kami proses
              lebih lanjut.
            </span>
          </div>
          <div class="d-flex">
            <span class="pe-3">3.9</span>
            <span>
              Apabila terdeteksi adanya kecurangan dalam menjalankan program affiliasi Komerce, maka hubungan antara member dan
              affiliator akan diputuskan.
            </span>
          </div>
          <div class="d-flex">
            <span class="pe-2">3.10</span>
            <span>
              Kami tidak melayani permintaan edit status afiliasi partner karena alasan diatas.
            </span>
          </div>
          <div class="d-flex">
            <span class="pe-3">3.11</span>
            <span>
              Peraturan afiliasi Komerce tidak dapat diganggu gugat dengan alasan apapun.
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="content-kewajibah mt-3">
      <div class="title-content">{{titleKewajiban}}</div>
      <div class="description-content mt-1">
        <div class="container p-0">
          <div class="d-flex">
            <span class="pe-3">4.1</span> &nbsp;
            <span>
              Melampirkan foto asli di foto profil akun affiliator.
            </span>
          </div>
          <div class="d-flex">
            <span class="pe-3">4.2</span>
            <span>
              Anda dilarang mendaftar dan menggunakan link afiliasi dan atau kode referral untuk mendaftar jasa layanan Komship,
              Komtim, Kompack, Komclass, hingga Boostr atas nama Anda sendiri. Anda hanya bisa mengajak orang lain dengan menggunakan
              link afiliasi dan atau kode referral tersebut.
            </span>
          </div>
          <div class="d-flex">
            <span class="pe-3">4.3</span>
            <span>
              Anda dilarang menggunakan spam atau cara curang lainnya untuk mempromosikan link afiliasi atau kode kupon Anda.
            </span>
          </div>
          <div class="d-flex">
            <span class="pe-3">4.4</span>
            <span>
              Anda dilarang mengatasnamakan diri Anda sebagai Komerce / Komship / Komtim / Kompack / Komclass / Boostr dalam promosi apapun.
            </span>
          </div>
          <div class="d-flex">
            <span class="pe-3">4.5</span>
            <span>
              Anda dilarang mempromosikan seluruh layanan Komerce dengan janji/bonus/fitur yang tidak sesuai dengan ketentuan Komerce.
              Pemberian bonus hanya dapat diberikan atas nama pribadi dan tidak mengatasnamakan Komerce.
            </span>
          </div>
          <div class="d-flex">
            <span class="pe-3">4.6</span>
            <span>
              Media promosi yang Anda gunakan dilarang mengandung konten yang melanggar hukum atau hal tidak pantas lainnya.
            </span>
          </div>
          <div class="d-flex">
            <span class="pe-3">4.7</span>
            <span>
              Affiliator hanya dapat memiliki 1 akun afiliasi aktif.
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="content-ketentuan mt-3">
      <div class="title-content">{{titleKetentuan}}</div>
      <div class="description-content mt-1">
        <div class="container p-0">
          <div class="d-flex">
            <span class="pe-3">5.1</span> &nbsp;
            <span>
              Affiliate Komerce berhak untuk merubah kebijakan di kemudian hari tanpa persetujuan pihak lain.
            </span>
          </div>
          <div class="d-flex">
            <span class="pe-3">5.2</span>
            <span>
              Syarat-syarat yang tidak tertulis dalam dokumen ini bersifat fleksibel, kesepakatan dapat dicapai dengan diskusi yang
              melibatkan kedua belah pihak.
            </span>
          </div>
          <div class="d-flex">
            <span class="pe-3">5.3</span>
            <span>
              Untuk pertanyaan seputar hal-hal yang tidak disebutkan dalam Syarat dan Ketentuan ini, Anda dapat bertanya melalui
              e-mail kami di kontak Affiliate Komerce atau menghubungi kami di nomor Affiliate Komerce.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>