import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-maintanance',
  templateUrl: './modal-maintanance.component.html',
  styleUrls: ['./modal-maintanance.component.scss']
})
export class ModalMaintananceComponent implements OnInit {

  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
  }
  handleClose(e: any): void{
    this.activeModal.dismiss()
  }
}
