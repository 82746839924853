<nav id="sidebar" [ngClass]="active ? 'sidebar position-fixed card pe-3 bg-white h-100 mt-4' : 'sidebar position-fixed card pe-0 bg-white h-100 mt-0'">
  <div [ngClass]="active ? 'card-body' : 'card-body p-3'">
    <div class="">
      <a routerLink="/" class="nav-link navItemClass" routerLinkActive="active-link">
        <div
          [ngClass]="activeButton === 'Dashboard' ? 'd-flex flex-row nav justify-content-start ps-3 pe-3 active' : 'd-flex flex-row nav justify-content-start ps-3 pe-3'"
          (click)="activeButton = 'Dashboard'">
          <i-feather name="home" [ngClass]="active ? 'me-2' : 'me-0'"></i-feather>
          <span *ngIf="active" class="link">Dashboard</span>
        </div>
      </a>
    </div>
    <div class="">
      <a routerLink="/riwayat-komisi/1" class="nav-link navItemClass" routerLinkActive="active-link">
        <div
          [ngClass]="activeButton === 'Riwayat Komisi' ? 'd-flex flex-row nav justify-content-start ps-3 pe-3 active' : 'd-flex flex-row nav justify-content-start ps-3 pe-3'"
          (click)="activeButton = 'Riwayat Komisi'">
          <img [ngClass]="active ? 'me-2' : 'me-0'"
            [src]="activeButton === 'Riwayat Komisi' ? 'https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/empty-wallet.svg' : 'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/empty-wallet.svg'"
            alt="">
          <span *ngIf="active" class="link">Riwayat Komisi</span>
        </div>
      </a>
    </div>
    <div *ngIf="active">
      <a routerLink="#" class="accordion accordion-flush nav-link navItemClass" routerLinkActive="active-link">
        <div
          [ngClass]="activeButton === '' ? ' collapsed d-flex flex-row nav justify-content-start ps-3 pe-3 active' : ' collapsed d-flex flex-row nav justify-content-start ps-3 pe-3'"
          data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false"
          aria-controls="flush-collapseOne" (click)="activeButton = ''">
          <i-feather name="settings" class="me-2"></i-feather>
          <span *ngIf="active" class="link pe-2">Pengaturan</span>
          <i-feather *ngIf="active" name="chevron-down" class="me-2"></i-feather>
        </div>
      </a>
    </div>
    <div *ngIf="!active">
      <div class="btn-group dropend">
        <a routerLink="#" class="dropdown" data-bs-toggle="dropdown" aria-expanded="false" routerLinkActive="active-link">
          <div
            [ngClass]="activeButton === 'Pengaturan' ? ' collapsed d-flex flex-row nav justify-content-start ps-3 pe-3 active' : ' collapsed d-flex flex-row nav justify-content-start ps-3 pe-3'"
            (click)="activeButton = ''">
            <i-feather name="settings"></i-feather>
          </div>
        </a>
        <ul class="dropdown-menu">
          <a routerLink="/pengaturan/profile" class="nav-link navItemClass" routerLinkActive="active-link">
            <div
              [ngClass]="activeButton === 'Pengaturan' ? 'd-flex flex-row link-drop justify-content-start ps-2 pe-2 active' : 'd-flex flex-row link-drop justify-content-start ps-2 pe-2'"
              (click)="activeButton = ''">
              <span class="link">Profile</span>
            </div>
          </a>
          <a routerLink="/pengaturan/rekening-bank" class="nav-link navItemClass" routerLinkActive="active-link">
            <div
              [ngClass]="activeButton === 'Pengaturan' ? 'd-flex flex-row link-drop justify-content-start ps-2 pe-2 active' : 'd-flex flex-row link-drop justify-content-start ps-2 pe-2'"
              (click)="activeButton = ''">
              <span class="link">Rekening Bank</span>
            </div>
          </a>
        </ul>
      </div>
    </div>
    <div *ngIf="active" id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
      data-bs-parent="#accordionFlushExample">
      <a routerLink="/pengaturan/profile" class="nav-link navItemClass" routerLinkActive="active-link">
        <div
          [ngClass]="activeButton === 'Profile' ? 'd-flex flex-row nav justify-content-start ps-3 pe-3 active' : 'd-flex flex-row nav justify-content-start ps-3 pe-3'"
          (click)="activeButton = 'Profile'">
          <i-feather name="person" class="me-2"></i-feather>
          <span class="link">Profile</span>
        </div>
      </a>
      <a routerLink="/pengaturan/rekening-bank" class="nav-link navItemClass" routerLinkActive="active-link">
        <div
          [ngClass]="activeButton === 'Rekening Bank' ? 'd-flex flex-row nav justify-content-start ps-3 pe-3 active' : 'd-flex flex-row nav justify-content-start ps-3 pe-3'"
          (click)="activeButton = 'Rekening Bank'">
          <i-feather name="bank" class="me-2"></i-feather>
          <span class="link">Rekening Bank</span>
        </div>
      </a>
    </div>
  </div>
</nav>
