<div *ngIf="isModalVisible" class="d-flex flex-column align-items-center justify-items-center ps-5 pe-5 pt-3 pb-3">
  <div  class="body-card d-flex flex-column align-items-center justify-items-center">
    <img height="250px" [src]="data[selectIndicator].image" alt="">
  <div class="subtitle text-center ">{{data[selectIndicator].title}}</div>
  <div class="text-center subdesc mt-2">{{data[selectIndicator].desc}}</div>
  </div>
  <div class="d-flex flex-row w-100 mt-5 mb-3">
    <div>
      <button [disabled]="0 === this.selectIndicator" (click)="handleBack()" class="btn btn-primary">Back</button>
    </div>
    <div class="w-100 flex-1 d-flex align-items-center justify-items-center">
      <div class="indicator d-flex flex-row align-items-center justify-content-center w-100">
        <div *ngFor="let item of data; let i = index" class="item" [ngClass]="{'active': selectIndicator === i}"></div>
      </div>
    </div>
    <div>
      <button *ngIf="this.data.length-1 > this.selectIndicator" (click)="handleNext()" class="btn btn-primary">Next</button>
      <button *ngIf="this.data.length-1 === this.selectIndicator" (click)="handleFinish()" class="btn btn-primary">Finish</button>
    </div>
  </div>
</div>
