import { NgModule } from '@angular/core';

import { FeatherModule } from 'angular-feather';
import {
  Camera,
  Settings,
  Heart,
  Github,
  Eye,
  EyeOff,
  Grid,
  Bell,
  Home,
  AlertCircle,
  Download,
  ChevronRight,
  ChevronDown,
  ChevronUp,
  ChevronLeft,
  Edit2,
  CheckCircle,
  Mail,
  Phone,
  Copy,
  Lock,
  FileText,
  Calendar,
  Search,
  LogOut,
  User,
  MapPin,
  Youtube,
  Instagram,
  Facebook,
  Linkedin,
  FolderMinus,
} from 'angular-feather/icons';

// Select some icons (use an object, not an array)
const icons = {
  Camera,
  Heart,
  Github,
  Eye,
  EyeOff,
  Lock,
  Grid,
  ChevronRight,
  ChevronDown,
  ChevronUp,
  ChevronLeft,
  Bell,
  Home,
  Settings,
  AlertCircle,
  Download,
  Edit2,
  CheckCircle,
  Mail,
  Phone,
  Copy,
  FileText,
  Calendar,
  Search,
  LogOut,
  User,
  MapPin,
  Youtube,
  Instagram,
  Facebook,
  Linkedin,
  FolderMinus,
};

@NgModule({
  imports: [
    FeatherModule.pick(icons)
  ],
  exports: [
    FeatherModule
  ]
})
export class IconsModule { }
