<form [action]="">
  <div class="d-flex flex-column gap-3 justify-content-center align-items-center">
    <div class="d-flex justify-content-end w-100 p-2">
      <img (click)="handleClose($event)" style="cursor: pointer;" src="assets/images/close-modal.svg" alt="">
    </div>
    <div class="d-flex flex-column gap-3 ps-4 pe-4">
      <div class="title text-center">{{tittle}}</div>
      <div class="desc text-center">{{description}}</div>
      <div class="d-flex flex-row mb-4 gap-3 w-100">
        <button (click)="handleClose($event)" class="btn btn-block w-100 btn-outline-primary">{{closeText}}</button>
        <button (click)="confirmClose($event)" class="btn btn-block w-100 btn-primary">{{confirmText}}</button>
      </div>
    </div>
   </div>
</form>
