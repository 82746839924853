<nav
  class="navbar fixed-top navbar-light bg-white ps-3 pe-3 d-flex justify-content-between"
>
  <span class="navbar-brand mb-0 h1">
    <button class="btn" (click)="collapseClick()">
      <i-feather name="grid" class="someclass"></i-feather>
    </button>
    <img
      class="ms-3 img-logo"
      height="30px"
      src="https://storage.googleapis.com/komerce/assets/Affiliate/Komerce Affiliate.svg"
      alt=""
    />
  </span>
  <div class="flex-row d-flex text-align-center align-items-center gap-3">
    <img
    *ngIf="detailProfile.is_pumkm === 1"
    src="https://storage.googleapis.com/komerce/assets/svg/Badge_PUMKM.svg"
    alt="Pendamping UMKM"
    style="width: 35px; cursor: pointer"
    ngbPopover="Kamu adalah Pendamping UMKM terdaftar di Komerce"
    triggers="mouseenter:mouseleave"
    placement="bottom"
    popoverClass="po-pumkm"
  />
    <div class="title">
      {{ detailProfile.full_name }}
    </div>
    <div ngbDropdown display="dynamic">
      <img
        [src]="
          detailProfile.photo_profile_url
            ? detailProfile.photo_profile_url
            : 'https://cdn.pixabay.com/photo/2014/04/03/10/32/businessman-310819_960_720.png'
        "
        class="main-profile-img rounded-circle"
        style="width: 35px; cursor: pointer"
        alt="Avatar"
        id="dropdownBasic1"
        ngbDropdownToggle
      />
      <div
        ngbDropdownMenu
        aria-labelledby="dropdownBasic1"
        class="dropdown-menu dropdown-menu-end"
      >
        <div>
          <button (click)="logout()" ngbDropdownItem class="text-center">
            <i-feather name="log-out" class="me-2"></i-feather>
            <span class="mx-3">Logout</span>
          </button>
        </div>
      </div>
    </div>

    <div class="dropdown position-relative me-3 p-2">
      <i-feather
        name="bell"
        class="someclass"
        data-bs-toggle="dropdown"
        data-bs-auto-close="outside"
        style="cursor: pointer"
      ></i-feather>
      <span
        *ngIf="getNotif.count_notify >= 1"
        class="position-absolute top-10 start-200 translate-middle badge rounded-pill bg-danger"
      >
        {{ getNotif.count_notify }}
        <span class="visually-hidden">unread messages</span>
      </span>
      <div class="dropdown-menu dropdown-menu-notif p-0">
        <div class="container">
          <div class="row row-title py-2">
            <div class="col-6 title-1"><span>Notification</span></div>
            <div class="col-6 title-2">
              <button
                (click)="clearAllNotif()"
                [class]="
                  getNotif.detail_notify && !isClear
                    ? 'span-2 btn btn-sm'
                    : 'span-1 btn btn-sm disabled'
                "
              >
                Bersihkan notifikasi
              </button>
            </div>
          </div>
          <div class="row row-description scrollable-container">
            <div *ngFor="let item of getNotif.detail_notify" class="p-0">
              <div
                [class]="
                  item.is_read === 0
                    ? 'alert alert-hovered py-3 m-0'
                    : 'alert not-hovered py-3 m-0'
                "
                role="alert"
              >
                <div class="row row-notif">
                  <div class="col-2 p-0 image-content">
                    <img
                      src="../../../../../../assets/images/IconNotification.svg"
                      width="70px"
                      alt=""
                      *ngIf="item.headline === 'Yeah! Komisimu Cair 😄'"
                    />
                    <img
                      src="../../../../../../assets/images/IconNotificationDanger.svg"
                      width="70px"
                      alt=""
                      *ngIf="item.headline === 'Penarikan Komisimu ditolak 🙁'"
                    />
                  </div>
                  <div class="col-10 p-0">
                    <div class="d-flex justify-content-between">
                      <div class="sub-title-1" style="text-align: right">
                        <span>{{ item.headline }}</span>
                      </div>
                      <div class="sub-title-2">
                        <small class="me-2">{{ item.created_notify }}</small>
                        <img
                          (click)="clearNotif(item.notify_id)"
                          style="cursor: pointer"
                          width="15px"
                          class="me-2"
                          src="assets/images/close-modal.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      class="desc-content mt-2"
                      (click)="item.is_read === 1 ? null : readNotif(item.notify_id)"
                    >
                      <span>
                        {{ item.content }}
                      </span>
                      <span *ngIf="item.headline === 'Penarikan Komisimu ditolak 🙁'">
                        Silahkan klik <b (click)="open(modalReject, item.notes)" class="link-modal">detail</b> berikut
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="image-empty"
              *ngIf="!getNotif.detail_notify || isClear"
              style="padding-top: 30px"
            >
              <img
                src="https://storage.googleapis.com/komerce/assets/svg/Empty-notification.svg"
                alt=""
              />
              <div class="desc-image py-2">
                <span>Belum ada notifikasi</span>
              </div>
            </div>
          </div>
          <div class="row btn-notif py-3">
            <div class="container">
              <button
                type="button"
                (click)="readAllNotif()"
                [class]="
                  !getNotif.detail_notify || getNotif.count_notify === 0
                    ? 'btn btn-secondary disabled w-100'
                    : 'btn btn-warning w-100'
                "
              >
                <span>Baca Semua Notifikasi</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #modalReject let-c="close">
      <div class="my-4">
        <div class="d-flex flex-column justify-content-center gap-4 ps-4 pe-4">
          <div class="text-center">
            <h4><b>Penarikan Komisi Ditolak</b></h4>
          </div>
          <img height="200px" src="https://storage.googleapis.com/komerce/assets/svg/make-money-ditolak.svg" alt="">
          <div class="text-center">
            <div>
              {{ getNotesNotify }}
            </div>
          </div>
          <button (click)="c('close')" class="btn btn-primary w-100">Oke</button>
        </div>
      </div>
    </ng-template>
  </div>
</nav>
