<div class="layout d-flex flex-column p-md-5 p-3">
  <div class="h-100 d-flex flex-md-row flex-column-reverse mt-3 mt-md-0 align-items-md-center">
    <div class="col-md-5 col-12 mt-3 mt-md-0 d-flex justify-content-center align-items-center h-100">
      <router-outlet></router-outlet>
    </div>
    <div class="col-md-7 img-bg col-12 p-3 d-flex justify-content-center ">
      <img width="80%" src="assets/images/auth-image.svg" alt="">
    </div>
  </div>
  <div class="d-flex justify-content-md-end justify-content-center mt-3 mt-md-0 img-bg">
    <img src="https://storage.googleapis.com/komerce/assets/Affiliate/Komerce%20Affiliate_white.png" width="150px" alt="">
  </div>
</div>
