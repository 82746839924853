import {Component, ElementRef, HostListener, OnInit} from '@angular/core';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  active: boolean = true
  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
  }
  collapseToogle(e: any):void{
    this.active = e
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target:any) {
    if(target.className === 'layer'){
      this.active = false
    }

  }

  isActive(e:any){
    this.active = e
  }
}
