import { Directive, HostListener, ElementRef, OnInit, SimpleChanges, OnChanges, Input } from '@angular/core';

@Directive({
  selector: '[myCurrencyFormatter]',
  // providers: [NgModel]
})
export class CustomCurrencyDirective implements OnInit, OnChanges {
  @Input() el: HTMLInputElement;
  @Input() elValue: any;

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.el.value = this.el.value
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  ngOnChanges(changes: SimpleChanges){
    if(changes['elValue']){
      // console.log('input changed', changes);
      this.el.value = changes['elValue'].currentValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }


  @HostListener('input', ['$event.target.value'])
  onBlur(value: any) {
    this.el.value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
